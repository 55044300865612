import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { Provider } from 'react-redux';
import { AuthProvider } from './context/AuthContext';
import { store } from './app/store';
import 'dayjs/locale/ru'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import dayjs from 'dayjs';
import 'dayjs/locale/ru'

dayjs.extend(localizedFormat)
dayjs.locale('ru')



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <Provider store={store}>
        <App/>
      </Provider>
    </AuthProvider>
  </React.StrictMode>
);
