export interface User {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    avatar: string;
    is_superuser: boolean;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    roles: Role[];
  }
  
  export interface Role {
    id: number;
    name: string;
    allow: UserPermission;
    disallow: UserPermission;
  }

  export enum Permission {
    NoRights = 0,
    Admin = 1 << 0,
    Massager = 1 << 1,
    ViewStatistics = 1 << 2,
    ManageUsers = 1 << 3,
    ManageBotSettings = 1 << 4,
    ViewAnswersQuestions = 1 << 5,
    ViewProducts = 1 << 6,
  }
  
export type UserPermission = Permission;