/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { type AxiosInstance, type AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {
  /**
   * show loading status
   */
  loading?: boolean;
  /**
   * display error message
   */
  showError?: boolean;
  /**
   * data security, extended fields are encrypted using the specified algorithm
   */
  security?: Record<string, 'md5' | 'sha1' | 'aes' | 'des'>;
  /**
   * indicates whether Authorization credentials are required for the request
   * @default true
   */
  withAuthorization?: boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '/api/assistant';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AssistantService {
  /**
   * Get current assistant model
   */
  static model(options: IRequestOptions = {}): Promise<api_ValueResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/assistant/model';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   * Set current assistant model
   */
  static model1(
    params: {
      /** Value request JSON */
      request: api_ValueRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_OkResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/assistant/model';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['request'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get list of avaliable assistant models
   */
  static models(options: IRequestOptions = {}): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/assistant/models';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get current ai usage statistic
   */
  static daily(
    params: {
      /** Request JSON */
      request: api_StatDateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<models_AssistantTotalUsageInfo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/assistant/stat/daily';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['request'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ContentAnswersService {
  /**
   * Get question answer
   */
  static answer(
    params: {
      /** ID of the question answer */
      id?: number;
      /** Searching string for question answer */
      searchingString?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<models_QuestionAnswerData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/content/answer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'], searching_string: params['searchingString'] };

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update question answer
   */
  static answer1(
    params: {
      /** Question answer data */
      request: models_QuestionAnswerData;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_OkResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/content/answer';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['request'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Insert question answer
   */
  static answer2(
    params: {
      /** Question answer data */
      request: models_QuestionAnswerData;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_IdResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/content/answer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['request'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete question answer
   */
  static answer3(
    params: {
      /** ID */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_OkResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/content/answer/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get question answers
   */
  static answers(
    params: {
      /** Number of question answers to return */
      limit?: number;
      /** Offset for pagination */
      offset?: number;
      /** Search string */
      searchString?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<models_QuestionAnswerData[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/content/answers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { limit: params['limit'], offset: params['offset'], search_string: params['searchString'] };

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
}

export class ContentProductsService {
  /**
   * Get product
   */
  static product(
    params: {
      /** ID of the product */
      id?: number;
      /** Searching string for product */
      searchingString?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<models_ProductData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/content/product';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'], searching_string: params['searchingString'] };

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   * Update product
   */
  static product1(
    params: {
      /** Product data */
      request: models_ProductData;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_OkResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/content/product';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['request'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Insert product
   */
  static product2(
    params: {
      /** Product data */
      request: models_ProductData;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_IdResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/content/product';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['request'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete product
   */
  static product3(
    params: {
      /** ID */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_OkResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/content/product/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get products
   */
  static products(
    params: {
      /** Number of products to return */
      limit?: number;
      /** Offset for pagination */
      offset?: number;
      /** Search string */
      searchString?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<models_ProductData[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/content/products';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { limit: params['limit'], offset: params['offset'], search_string: params['searchString'] };

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
}

export class ContentService {
  /**
   * Content get pair values list
   */
  static value(options: IRequestOptions = {}): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/content/value';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   * Content set pair value
   */
  static value1(
    params: {
      /** Request JSON */
      request: api_SetValueRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_OkResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/content/value';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['request'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Content get pair value
   */
  static value2(
    params: {
      /** Field of the param */
      field: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_ValueResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/content/value/{field}';
      url = url.replace('{field}', params['field'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   * Content set pair value
   */
  static values(
    params: {
      /** Request JSON */
      request: api_SetValuesRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_OkResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/content/values';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['request'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class FilesService {
  /**
   * Get Telegram file
   */
  static telegram(
    params: {
      /** File name */
      file: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/files/telegram/{file}';
      url = url.replace('{file}', params['file'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   * Upload files
   */
  static upload(
    params: {
      /** Files to upload */
      files: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_ItemsResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/files/upload';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class MessagerService {
  /**
   * Delete message
   */
  static delete(
    params: {
      /** Delete message request */
      request: api_DeleteMessageRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_OkResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/messager/messages/delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['request'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Edit caption message
   */
  static editCaption(
    params: {
      /** Edit caption message request */
      request: api_EditCaptionMessageRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_OkResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/messager/messages/editCaption';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['request'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Edit text message
   */
  static editText(
    params: {
      /** Edit text message request */
      request: api_EditTextMessageRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_OkResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/messager/messages/editText';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['request'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static list(
    params: {
      /** Number of messages to return */
      limit?: number;
      /** Offset for pagination */
      offset?: number;
      /** ID of the chat */
      chatId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<models_MessageInfo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/messager/messages/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { limit: params['limit'], offset: params['offset'], chat_id: params['chatId'] };

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   * Search messages
   */
  static search(
    params: {
      /** Number of messages to return */
      limit?: number;
      /** Offset for pagination */
      offset?: number;
      /** ID of the chat */
      chatId?: number;
      /** Search query */
      searchString: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<models_MessageInfo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/messager/messages/search';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        limit: params['limit'],
        offset: params['offset'],
        chat_id: params['chatId'],
        search_string: params['searchString']
      };

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   * Send audio message
   */
  static sendAudio(
    params: {
      /** Send audio message request */
      request: api_SendMediaMessageRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_MessageIdResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/messager/messages/sendAudio';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['request'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Send document message
   */
  static sendDocument(
    params: {
      /** Send document message request */
      request: api_SendMediaMessageRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_MessageIdResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/messager/messages/sendDocument';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['request'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Send media group message
   */
  static sendMediaGroup(
    params: {
      /** Send media group message request */
      request: api_SendMediaGroupMessageRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_MessageIdsResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/messager/messages/sendMediaGroup';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['request'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Send photo message
   */
  static sendPhoto(
    params: {
      /** Send photo message request */
      request: api_SendMediaMessageRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_MessageIdResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/messager/messages/sendPhoto';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['request'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Send text message
   */
  static sendText(
    params: {
      /** Send text message request */
      request: api_SendTextMessageRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_MessageIdResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/messager/messages/sendText';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['request'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Send video message
   */
  static sendVideo(
    params: {
      /** Send video message request */
      request: api_SendMediaMessageRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_MessageIdResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/messager/messages/sendVideo';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['request'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Send voice message
   */
  static sendVoice(
    params: {
      /** Send voice message request */
      request: api_SendMediaMessageRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<api_MessageIdResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/messager/messages/sendVoice';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['request'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get user
   */
  static user(
    params: {
      /** User ID */
      userId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<models_UserInfo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/messager/user/{user_id}';
      url = url.replace('{user_id}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get users
   */
  static users(
    params: {
      /** Number of users to return */
      limit?: number;
      /** Offset for pagination */
      offset?: number;
      /** Search string */
      searchString?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<models_UserInfo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/messager/users';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { limit: params['limit'], offset: params['offset'], search_string: params['searchString'] };

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
}

export class PingService {
  /**
   * Ping pong
   */
  static ping(options: IRequestOptions = {}): Promise<api_MessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/ping';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body */

      console.warn('适配移动开发（iOS13 等版本），只有 POST、PUT 等请求允许带body');

      axios(configs, resolve, reject);
    });
  }
}

export interface api_DeleteMessageRequest {
  /**  */
  chat_id?: number;

  /**  */
  message_id?: number;
}

export interface api_Document {
  /**  */
  file?: string;

  /**  */
  filename?: string;
}

export interface api_EditCaptionMessageRequest {
  /**  */
  caption?: string;

  /**  */
  chat_id?: number;

  /**  */
  message_id?: number;
}

export interface api_EditTextMessageRequest {
  /**  */
  caption?: string;

  /**  */
  chat_id?: number;

  /**  */
  message_id?: number;
}

export interface api_IdResponse {
  /**  */
  id?: number;
}

export interface api_ItemsResponse {
  /**  */
  items?: string[];
}

export interface api_MessageIdResponse {
  /**  */
  message_id?: number;
}

export interface api_MessageIdsResponse {
  /**  */
  message_ids?: number[];
}

export interface api_MessageResponse {
  /**  */
  message?: string;
}

export interface api_OkResponse {
  /**  */
  ok?: boolean;
}

export interface api_SendMediaGroupMessageRequest {
  /**  */
  audios?: string[];

  /**  */
  caption?: string;

  /**  */
  chat_id?: number;

  /**  */
  documents?: api_Document[];

  /**  */
  photos?: string[];

  /**  */
  videos?: string[];
}

export interface api_SendMediaMessageRequest {
  /**  */
  caption?: string;

  /**  */
  chat_id?: number;

  /**  */
  file?: string;

  /**  */
  filename?: string;
}

export interface api_SendTextMessageRequest {
  /**  */
  chat_id?: number;

  /**  */
  text?: string;
}

export interface api_SetValueRequest {
  /**  */
  key?: string;

  /**  */
  value?: string;
}

export interface api_SetValuesRequest {
  /**  */
  values?: object;
}

export interface api_StatDateRequest {
  /**  */
  from?: string;

  /**  */
  to?: string;
}

export interface api_ValueRequest {
  /**  */
  value?: string;
}

export interface api_ValueResponse {
  /**  */
  value?: string;
}

export interface models_AssistantTotalUsageInfo {
  /** Cost */
  cost?: number;

  /** Daily */
  daily_usage?: models_AssistantUsageInfo[];
}

export interface models_AssistantUsageInfo {
  /** Cost */
  cost?: number;

  /** Date */
  date?: string;

  /** Usage fields */
  input_tokens?: number;

  /**  */
  output_tokens?: number;

  /** Base fields */
  role?: string;

  /**  */
  total_tokens?: number;
}

export interface models_Audio {
  /** Duration of the audio in seconds as defined by sender */
  duration?: number;

  /** File is an identifier for this file, which can be used to download or
reuse the file */
  file?: string;

  /** FileSize file size

optional */
  file_size?: number;

  /** Thumbnail is the album cover to which the music file belongs

optional */
  thumb?: any | null;

  /** Title of the audio as defined by sender or by audio tags

optional */
  title?: string;
}

export interface models_ChaInfo {
  /** Type of chat, can be either “private”, “group”, “supergroup” or “channel” */
  type?: string;

  /** BigPhotoFile is a file identifier of big (640x640) chat photo.
This file can be used only for photo download and
only for as long as the photo is not changed. */
  big_photo_file?: string;

  /** |> 1. Base
ID is a unique identifier for this chat */
  chat_id?: number;

  /** Description for groups, supergroups and channel chats

optional */
  description?: string;

  /** InviteLink is a chat invite link, for groups, supergroups and channel chats.
Each administrator in a chat generates their own invite links,
so the bot must first generate the link using exportChatInviteLink

optional */
  invite_link?: string;

  /** |> 2. Photo
SmallPhotoFile is a file identifier of small (160x160) chat photo.
This file can be used only for photo download and
only for as long as the photo is not changed. */
  small_photo_file?: string;

  /** optional */
  text?: string;

  /** Title for supergroups, channels and group chats

optional */
  title?: string;

  /** UserName chat's username

optional */
  username?: string;
}

export interface models_Contact {
  /** FirstName contact's first name */
  first_name?: string;

  /** LastName contact's last name

optional */
  last_name?: string;

  /** PhoneNumber contact's phone number */
  phone_number?: string;

  /** UserID contact's user identifier in Telegram

optional */
  user_id?: number;

  /** VCard is additional data about the contact in the form of a vCard.

optional */
  vcard?: string;
}

export interface models_Document {
  /** FileID is an identifier for this file, which can be used to download or
reuse the file */
  file?: string;

  /** FileName original filename as defined by sender

optional */
  file_name?: string;

  /** FileSize file size

optional */
  file_size?: number;

  /** Thumbnail document thumbnail as defined by sender

optional */
  thumb?: any | null;
}

export interface models_KeyboardInfo {
  /**  */
  buttons?: string[];
}

export interface models_MessageInfo {
  /** |> 2. Attachments */
  audio?: any | null;

  /** Caption for the animation, audio, document, photo, video or voice, 0-1024 characters;

optional */
  caption?: string;

  /**  */
  contact?: models_Contact;

  /** Date of the message was sent in Unix time */
  date?: string;

  /**  */
  document?: models_Document;

  /** EditDate of the message was last edited in Unix time;

optional */
  edit_date?: string;

  /** ForwardDate for forwarded messages, date the original message was sent in Unix time;

optional */
  forward_date?: string;

  /** Forwarded
ForwardFrom for forwarded messages, sender of the original message;

optional */
  forward_from?: any | null;

  /** ForwardFromChat for messages forwarded from channels,
information about the original channel;

optional */
  forward_from_chat?: any | null;

  /** From is a sender */
  is_mine?: boolean;

  /** |> 3. Markup */
  keyboard?: any | null;

  /** MediaGroupID is the unique identifier of a media message group this message belongs to;

optional */
  media_group_id?: string;

  /** |> 1. Basic fields
MessageID is a unique message identifier inside this chat */
  message_id?: number;

  /**  */
  photo?: models_PhotoSize[];

  /** Pinned message */
  pinned_message?: any | null;

  /**  */
  poll?: models_Poll;

  /** Replied
ReplyToMessage for replies, the original message.
Note that the Message object in this field will not contain further ReplyToMessage fields
even if it itself is a reply;

optional */
  reply_to_message?: any | null;

  /**  */
  sticker?: models_Sticker;

  /** Text is for text messages, the actual UTF-8 text of the message, 0-4096 characters;

optional */
  text?: string;

  /** Via bot
ViaBot through which the message was sent;

optional */
  via_bot?: any | null;

  /**  */
  video?: models_Video;

  /**  */
  video_note?: models_VideoNote;

  /**  */
  voice?: models_Voice;
}

export interface models_PhotoSize {
  /** File identifier for this file, which can be used to download or reuse
the file */
  file?: string;

  /** FileSize file size

optional */
  file_size?: number;

  /** Height photo height */
  height?: number;

  /** Width photo width */
  width?: number;
}

export interface models_Poll {
  /** Options is the list of poll options */
  options?: string[];

  /** Question is the poll question, 1-255 characters */
  question?: string;
}

export interface models_ProductData {
  /**  */
  date?: string;

  /**  */
  description?: string;

  /** Id */
  id?: number;

  /** Meta */
  link?: string;

  /**  */
  meta?: string;

  /** Content */
  name?: string;
}

export interface models_QuestionAnswerData {
  /** Content */
  answer?: string;

  /**  */
  date?: string;

  /** Id */
  id?: number;

  /** Keywords */
  keywords?: string;

  /**  */
  question?: string;
}

export interface models_Sticker {
  /** Emoji associated with the sticker

optional */
  emoji?: string;

  /** FileID is an identifier for this file, which can be used to download or
reuse the file */
  file?: string;

  /** FileSize

optional */
  file_size?: number;

  /** Height sticker height */
  height?: number;

  /** IsAnimated true, if the sticker is animated

optional */
  is_animated?: boolean;

  /** SetName of the sticker set to which the sticker belongs

optional */
  set_name?: string;

  /** Thumbnail sticker thumbnail in the .WEBP or .JPG format

optional */
  thumb?: any | null;

  /** Width sticker width */
  width?: number;
}

export interface models_UserInfo {
  /** 2. Visual fields
FirstName user's or bot's first name */
  first_name?: string;

  /** Is blocked by this user or not */
  is_banned?: boolean;

  /** IsBot true, if this user is a bot

optional */
  is_bot?: boolean;

  /** LanguageCode IETF language tag of the user's language
more info: https://en.wikipedia.org/wiki/IETF_language_tag

optional */
  language_code?: string;

  /** 4. Activity
The date of last activity this user in the bot */
  last_activity_date?: string;

  /** Last last message in the dialog */
  last_message?: any | null;

  /** LastName user's or bot's last name

optional */
  last_name?: string;

  /** The date of registered this user in the bot */
  time?: string;

  /** 1. Basic fields
ID is a unique identifier for this user or bot */
  user_id?: number;

  /** 3. Photo fields */
  user_profile_photos?: any | null;

  /** UserName user's or bot's username

optional */
  username?: string;
}

export interface models_UserProfilePhotos {
  /** Photos requested profile pictures (in up to 4 sizes each) */
  photos?: models_PhotoSize[];

  /** TotalCount total number of profile pictures the target user has */
  total_count?: number;
}

export interface models_Video {
  /** Duration of the video in seconds as defined by sender */
  duration?: number;

  /** File identifier for this file, which can be used to download or reuse
the file */
  file?: string;

  /** FileName is the original filename as defined by sender

optional */
  file_name?: string;

  /** FileSize file size

optional */
  file_size?: number;

  /** Height video height as defined by sender */
  height?: number;

  /** Thumbnail video thumbnail

optional */
  thumb?: any | null;

  /** Width video width as defined by sender */
  width?: number;
}

export interface models_VideoNote {
  /** Duration of the video in seconds as defined by sender */
  duration?: number;

  /** File identifier for this file, which can be used to download or reuse the file */
  file?: string;

  /** FileSize file size

optional */
  file_size?: number;

  /** Length video width and height (diameter of the video message) as defined by sender */
  length?: number;

  /** Thumbnail video thumbnail

optional */
  thumb?: any | null;
}

export interface models_Voice {
  /** Duration of the audio in seconds as defined by sender */
  duration?: number;

  /** File identifier for this file, which can be used to download or reuse the file */
  file?: string;

  /** FileSize file size

optional */
  file_size?: number;
}
