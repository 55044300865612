
import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../routes/common/headerSlice'
import dataSlice from '../routes/common/dataSlice'

const combinedReducer = {
    header : headerSlice,
    data: dataSlice,
    // rightDrawer : rightDrawerSlice,
    // modal : modalSlice,
    // lead : leadsSlice
  }
  
export const store = configureStore({
      reducer: combinedReducer
  })

  // Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch