import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import type { LoaderFunctionArgs } from 'react-router-dom';
import Layout from './containers/Layout';
import ErrorPage from './pages/error-page';
import SuspenseContent from './containers/SuspanceContent';
import { Suspense, lazy, useMemo } from 'react';
import RootLogin from './pages/login/RootLogin';
import TelegramLogin from './pages/login/TelegramLogin';
import { useAuth } from './context/AuthContext';
import IndexPage from './pages/index-page';
import initializeApp from './app/init';
import checkAuth from './app/auth';
import InvalidPage from './pages/invalid-page';

const Dashboard = lazy(() => import('./pages/protected/dashboard'));
const Messager = lazy(() => import('./pages/protected/Messager'));
const Chat = lazy(() => import('./routes/messager/chat'));
const SettingsBot = lazy(() => import('./pages/protected/SettingsBot'));
const Login = lazy(() => import('./pages/login/Login'));
const UserSettings = lazy(() => import('./pages/protected/UserSettings'));
const ContentAnsvers = lazy(() => import('./pages/protected/contentAnsvers'));
const ContentProducts = lazy(() => import('./pages/protected/contentProducts'));
const Users = lazy(() => import('./pages/protected/Users'));
const UserRoles = lazy(() => import('./pages/protected/UserRoles'));


initializeApp()

function App() {
  const auth = useAuth();
  checkAuth(auth);

  const privateLoader = ({ request }: LoaderFunctionArgs) =>{
    if (!auth.isAuthenticated) {
      let params = new URLSearchParams();
      params.set('from', new URL(request.url).pathname);
      return redirect('/login?' + params.toString());
    }
    return { user: auth.user }
  }
  const router =  useMemo(() => createBrowserRouter([
    {
      path: "/",
      element: <IndexPage/>,
      errorElement: <ErrorPage/>
    },
    {
      path: "/app",
      element: <Layout/>,
      errorElement: <ErrorPage/>,
      loader: privateLoader,
      children: [
        {
          index: true,
          element: <Dashboard/>
        },
        {
          path: "messager",
          element: <Messager/>,
          children: [
            {
              path: ":chatId",
              element: <Chat/>,
            }],
        },
        {
          path: "content-ansvers",
          element: <ContentAnsvers/>,
        },
        {
          path: "content-products",
          element: <ContentProducts/>,
        },
        {
          path: "bot-settings",
          element: <SettingsBot/>,
        },
        {
          path: "user-settings",
          element: <UserSettings/>,
        },
        {
          path: "users",
          element: <Users/>,
        },
        {
          path: "user-roles",
          element: <UserRoles/>,
        },
        
      ],
    },
    {
      path: "/login",
      element: <RootLogin/>,
      loader(){
        if (auth.isAuthenticated) {
            return redirect("/app")
        }
        return null
      },
      children: [
        {
          index: true,
          element: <Login/>,
        },
        {
          path: "telegram",
          element: <TelegramLogin/>,
        },
      ]
    },
    {
      path: "/invalid",
      element: <InvalidPage/>
    },
    {
      path: "/logout",
      async action(){
        auth.logout();
        return redirect("/");
      }
    },
    {
      path: "/test",
      element: <div>{auth.token}</div>
    }
  ]), [])
  
  return (
    <div className="App">
      <Suspense fallback={<SuspenseContent/>}>
        <RouterProvider router={router} />
      </Suspense>
    </div>
  );
}

export default App;
