import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";

const TelegramLogin = () => {
  const url = window.location.href;
  const [loading, setLoading] = useState(false);
  const {isAuthenticated, loginWithToken} = useAuth();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (error || isAuthenticated) {
      return
    }
    if (!loading && (!isAuthenticated || !error)) {
      setLoading(true);
      axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/auth/telegram${window.location.search}`)
      .then(async (resp) => { 
        if (resp.status === 200) {
          await loginWithToken(resp.data)
          setLoading(false);
          navigate("/app");
        }else{
          throw new Error("Something went wrong");
        }
       }).catch((error) => {
        setError(error.message);
        setLoading(false);
      });
    }
  }, []);
  return (
    <>
    {(loading && (!isAuthenticated || !error)) && <h1 className="text-5xl skeleton font-bold mb-5 text-center">Вход...</h1>}
    {(!loading && isAuthenticated) && <>
        <h1 className="text-5xl font-bold mb-5 text-center">Вход</h1>
        <h1 className="text-xl font-bold mb-5 text-center">Вы успешно вошли</h1>
        {/* <Navigate to="/app" /> */}
        {/* <button className="btn btn-accent" onClick={()=>navigate('/app')}>Продолжить</button> */}
    </>}
    {(error) && <>
        <h1 className="text-5xl font-bold mb-5 text-center">Вход</h1>
        <h1 className="text-xl font-bold mb-5 text-center">Произошла ошибка</h1>
        <div className="text-center">{error}</div>
        </>}
    </>
  );
};

export default TelegramLogin;
