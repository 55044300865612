import { useRouteError } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { UserData } from "../routes/messager/chat/types";


const InvalidPage = () => {
    useEffect(() => {
      axios.get("/api/auth/me").then((res) => { 
        const me =  res.data as UserData
        if (me.permissions != 0) {
            window.location.href = '/app/'
        }
       })
    
      return
    }, [])
    
    return ( 
        <div className="flex flex-col items-center justify-center h-screen w-screen">
             <div>
            <h1 className="font-bold">Ваш аккаунт не подтвержден/заблокирован</h1>
            <p>Ожидайте назначения</p>
            <img src="https://media.giphy.com/media/3o7aTskHEUdgCQAXde/giphy.gif" alt="Добро пожаловать!"/>
            </div>
        </div>
     );
}
 
export default InvalidPage;