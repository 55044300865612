import { createContext, useContext, useState } from "react";
import { UserData } from "../routes/messager/chat/types";
import UseToken from "../hooks/useToken";

interface UserToken {
    access: string;
    refresh: string;
  }
export interface AuthProviderData {
    token: string | undefined;
    user: UserData | null;
    isAuthenticated: boolean;
    loginWithToken(input: UserToken): Promise<void>;
    logout(): Promise<void>;
}
export const authContext = createContext<AuthProviderData>({}as AuthProviderData)


export function AuthProvider({ children }: { children: React.ReactNode }) {
    const {tokens, setTokens} = UseToken()
    const [user, setUser] = useState<UserData|null>(null)
    const [isAuthenticated, setIsAuthenticated] = useState(!!tokens?.access)
  
    return <authContext.Provider value={{
        token: tokens?.access,
        user,
        isAuthenticated,
        async loginWithToken(input: UserToken){
            setTokens(input)
            setIsAuthenticated(true)
        },
        async logout(){
            setTokens(null)
            setUser(null)
            setIsAuthenticated(false)
        }
    }}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
    return useContext(authContext);
  }

export const useAuth = () => useContext(authContext);