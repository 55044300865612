import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import RectangleGroupIcon from "@heroicons/react/24/outline/RectangleGroupIcon";

import { Link } from "react-router-dom";
import Subtitle from "../components/Typography/Subtitle";
import { Permission } from "../app/models/models";
import { useAppSelector } from "../app/hooks";
import { UserData } from "../routes/messager/chat/types";
import { useState } from "react";
import ChevronDownIcon from "@heroicons/react/24/outline/ChevronDownIcon";

interface LeftSidebarRoute {
  route: string;
  label: string;
  permissions?: Permission;
  submenu?: LeftSidebarRoute[];
}


const routes: LeftSidebarRoute[] = [
  { route: "/app/", label: "Dashboard", permissions: Permission.NoRights },
  {
    route: "/app/messager",
    label: "Мессенджер",
    permissions: Permission.Massager,
  },
  {
    route: "/app/bot-settings",
    label: "Настройки бота",
    permissions: Permission.ManageBotSettings,
  },
  { route: "/app/content-ansvers", label: "Вопрос-Ответ", permissions: Permission.ViewAnswersQuestions },
  { route: "/app/content-products", label: "Продукты", permissions: Permission.ViewProducts  },
  {
    route: "",
    label: "Система",
    permissions: Permission.ManageUsers,
    submenu: [
      { route: "/app/users", label: "Пользователи" },
      { route: "/app/user-roles", label: "Роли" },
    ],
  },
];

const LeftSidebar = () => {
  const close = (e: any) => {
    document.getElementById("left-sidebar-drawer")?.click();
  };
  const me = useAppSelector((state) => state.data.profile) as UserData;
  if (!me) {
    return null;
  }
  return (
    <div className="drawer-side z-30">
      <label htmlFor="left-sidebar-drawer" className="drawer-overlay"></label>
      <div className="navbar ml-5">
        <Subtitle>Web panel</Subtitle>
      </div>
      <ul className="menu p-4 overflow-y-auto w-80 bg-base-100 text-base-content">
        <button
          className="btn btn-ghost bg-base-300  btn-circle z-50 top-0 right-0 mt-4 mr-2 absolute lg:hidden"
          onClick={(e) => close(e)}
        >
          <XMarkIcon className="h-5 inline-block w-5" />
        </button>
        {routes.map((route, k) => {
          if (route.permissions === undefined) {
            return (
              <li key={k}>{
                route.submenu ? (<SubMenu submenu={route.submenu} name={route.label}/>):
                <Link to={route.route}>{route.label}</Link>
              }
              </li>
            );
          }
          if ((route.permissions|Permission.Admin) & me.permissions) {
            return (
              <li key={k}>{
                route.submenu ? (<SubMenu submenu={route.submenu} name={route.label}/>):
                <Link to={route.route}>{route.label}</Link>
              }
              </li>
            );
          }
          return null;
        })}
      </ul>
    </div>
  );
};

const SubMenu = ({submenu, name}:{submenu: any, name: string}) => {
  
  const [expanded, setExpanded] = useState(false)
  return(
    <div className="flex flex-col">
      <div className="w-full block " onClick={() => { setExpanded(!expanded) }}>
        {name}
        <ChevronDownIcon className={'w-5 h-5 mt-1 float-right delay-400 duration-500 transition-all  ' + (expanded ? 'rotate-180' : '')}/>
      </div>
      <div className={`w-full ${!expanded && 'hidden'}`}>
        <ul className="menu">
          {submenu.map((route: any, k: number) => (
            <li key={k}>
              <Link to={route.route}>{route.label} </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
 }

export default LeftSidebar;
