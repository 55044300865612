import { useRouteError } from "react-router-dom";


const ErrorPage = () => {
    const error = useRouteError() as any;
    console.error(error);
    return ( 
        <div className="flex flex-col items-center justify-center h-screen w-screen">
            <h1 className="text-4xl font-bold text-red-600 mb-4">Ошибка</h1>
            <p className="text-lg text-gray-200 mb-2">Произошла непредвиденная ошибка</p>
            <p className="text-gray-300">
                <i>{`${error.status} ${error.statusText || error.message}`}</i>
            </p>        
        </div>
     );
}
 
export default ErrorPage;