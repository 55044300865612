import Cookies from 'js-cookie';
import { useState } from 'react';

interface UserToken {
  access: string;
  refresh: string;
}

const UseToken = () => {
  const getTokens = (): UserToken | null => {
    const tokenString = localStorage.getItem('tokens');
    const tokens: UserToken | null = tokenString ? JSON.parse(tokenString) : null;
    return tokens ?? null;
  };

  const [tokens, setTokens] = useState<UserToken | null>(getTokens());

  const saveTokens = (userTokens: UserToken | null) => {
    localStorage.setItem('tokens', JSON.stringify(userTokens));
    Cookies.set("AuthTokenAccess", userTokens?.access as string)
    setTokens(userTokens);
  };

  const updateAccessToken = (newAccessToken: string) => {
    if (tokens) {
      const updatedTokens: UserToken = {
        access: newAccessToken,
        refresh: tokens.refresh
      };
      saveTokens(updatedTokens);
    }
  };
  
  return {
    setTokens: saveTokens,
    updateAccessToken,
    tokens
  };
};

export default UseToken;
