import { Outlet } from "react-router-dom";

const RootLogin = () => {
    return ( <div className="flex flex-col items-center justify-center h-screen w-screen bg-base-200">
    {/* // <div className="min-h-screen bg-base-200 flex items-center"> */}
    <div className="card mx-auto w-full max-w-md shadow-xl rounded-xl bg-base-100">
    <div className='px-24 py-10'>
        <Outlet/>
    </div>
    </div>
    </div> );
}
 
export default RootLogin;