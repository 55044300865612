const Avatar = ({ name, avatar }: { name: string; avatar: string }) => {
  return (
    <div className="avatar placeholder">
      <div className="w-10 h-10 rounded-full bg-neutral text-neutral-content">
      {!avatar ? 
            <span className="text-xl">{name[0]}</span>
            : 
            <img loading="lazy" alt={name[0]} src={avatar} />}
      </div>
    </div>
  );
};

export default Avatar;
