import axios from "axios";
import { AuthProviderData } from "../context/AuthContext";
import { serviceOptions } from "../api";

const checkAuth = (auth: AuthProviderData) => {
  /*  Getting token value stored in localstorage, if token is not present we will open login page 
        for all internal dashboard routes  */
  const PUBLIC_ROUTES = ["login"];

  const isPublicPage = PUBLIC_ROUTES.some((r) =>
    window.location.href.includes(r)
  );

  if (!auth.isAuthenticated && !isPublicPage) {
    window.location.href = "/login";
    return;
  } else {
    axios.defaults.headers.common["Authorization"] = `Bearer ${auth.token}`;
    const asistantInstance = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      // timeout: 5000,
    });
    asistantInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${auth.token}`;
    serviceOptions.axios = asistantInstance;

    axios.interceptors.request.use(
      function (config) {
        // UPDATE: Add this code to show global loading indicator
        document.body.classList.add("loading-indicator");
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        // UPDATE: Add this code to hide global loading indicator
        document.body.classList.remove("loading-indicator");
        return response;
      },
      function (error) {
        document.body.classList.remove("loading-indicator");
        const status = error.response?.status || 500;
        if (status === 401) {
          auth.logout()
          window.location.href = "/login";
        } else {
          return Promise.reject(error);
        }
      }
    );
    return auth.token;
  }
};

export default checkAuth;
