import { createSlice } from '@reduxjs/toolkit'
import { UserData } from '../messager/chat/types'


interface DataState {
    profile: UserData | null
}

const initialState: DataState = {
    profile: null,
}

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setProfile: (state, action) => {
            state.profile = action.payload
        },


        // removeNotificationMessage: (state, action) => {
        //     state.newNotificationMessage = ""
        // },

        // showNotification: (state, action) => {
        //     state.newNotificationMessage = action.payload.message
        //     state.newNotificationStatus = action.payload.status
        // },
    }
})

export const { setProfile,
    //  removeNotificationMessage, showNotification
     } = dataSlice.actions

export default dataSlice.reducer