import Bars3Icon from "@heroicons/react/24/outline/Bars3Icon";
import SunIcon from "@heroicons/react/24/outline/SunIcon";
import MoonIcon from "@heroicons/react/24/outline/MoonIcon";
import { useState, useEffect } from "react";
import { themeChange } from "theme-change";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuth } from "../context/AuthContext";
import Avatar from "../components/UserAvatar";
import { useAppSelector } from "../app/hooks";
import { UserData } from "../routes/messager/chat/types";




const Header = () => {
    const [currentTheme, setCurrentTheme] = useState(localStorage.getItem("theme"))

    const {pageTitle} = useSelector((state: any) => state.header)
    const me = useAppSelector((state) => state.data.profile) as UserData

    useEffect(() => {
        themeChange(false)
        if(currentTheme === null){
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ) {
                setCurrentTheme("dark")
            }else{
                setCurrentTheme("light")
            }
        }
        // 👆 false parameter is required for react project
      }, [currentTheme])

      const {logout} = useAuth()

  return (
    <>
      <div className="navbar sticky top-0 bg-base-500 z-10 shadow-md">
        {/* Logo and title */}
        <div className="flex-1">
        <label htmlFor="left-sidebar-drawer" className="btn btn-primary drawer-button lg:hidden">
          <Bars3Icon className="h-5 inline-block w-5"/>
        </label>
        <div className="btn btn-ghost normal-case text-xl">{pageTitle}</div>
        </div>
        {/* Theme toggel */}
        <div className="flex-none" >
            {/* Light and dark theme selection toogle **/}
            <label className="swap ">
                <input type="checkbox"/>
                <SunIcon data-set-theme="light" data-act-class="ACTIVECLASS" className={"fill-current w-6 h-6 "+(currentTheme === "dark" ? "swap-on" : "swap-off")}/>
                <MoonIcon data-set-theme="dark" data-act-class="ACTIVECLASS" className={"fill-current w-6 h-6 "+(currentTheme === "light" ? "swap-on" : "swap-off")} />
            </label>
        </div>
        {/* User menu */}
        <div className="dropdown dropdown-end ml-4">
            <label tabIndex={0} className="btn btn-ghost btn-circle">
                {me && <Avatar name={me.first_name} avatar={me.avatar}/>}
                {/* <div className="bg-neutral text-neutral-content rounded-full w-10"> */}
                    {/* <span className="text-xl">A</span>  */}
                    {/* TODO: add logic if not avatar  */}
                    {/* <img src="" alt="" /> */}
                {/* </div> */}
            </label>
            <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                <li className="justify-between">
                    <Link to={'/app/user-settings'}>Настройки профиля</Link>
                </li>
                <li><a href="/app" onClick={()=>logout()}>Выйти из профиля</a></li>
            </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
