import { Navigate, Outlet, useLocation } from "react-router-dom";
import LeftSidebar from "./LeftSidebar";
import Header from "./Header";
import { Suspense, useEffect, useState } from "react";
import SuspenseContent from "./SuspanceContent";
import { useDispatch } from "react-redux";
import { setProfile } from "../routes/common/dataSlice";
import axios, { AxiosError } from "axios";
import { useAppSelector } from "../app/hooks";
import { UserData } from "../routes/messager/chat/types";

const Layout = () => {
  const dispatch = useDispatch();
  const me  = useAppSelector(state => state.data.profile) as UserData
  const [error, setError] = useState(false);
  useEffect(() => {
    if(me) return
    axios
      .get("/api/me")
      .then((res) => {
        dispatch(setProfile(res.data));
      })
      .catch((err: AxiosError) => {
        console.log(err);
        if (err.response?.status === 401) {
          
        }
        setError(true);
      });
  }, []);
  const location = useLocation()
  if (me && me.permissions === 0) {
    return (<Navigate to="/invalid" replace/>);
  }
  return (
    <div className="drawer lg:drawer-open">
      <input
        id="left-sidebar-drawer"
        type="checkbox"
        className="drawer-toggle"
      />
      <div id="content" className="drawer-content flex flex-col">
        <Header />
        {!location.pathname.includes('/app/messager')?
        <main className="flex-1 overflow-y-auto md:pt-4 pt-4 px-6 bg-base-200">
        <Suspense fallback={<SuspenseContent />}>
          <Outlet />
        </Suspense>
      </main>:
      <main className="flex-1 overflow-y-auto bg-base-200">
      <Suspense fallback={<SuspenseContent />}>
        <Outlet />
      </Suspense>
    </main>
        }
        {/* <main className="flex-1 overflow-y-auto md:pt-4 pt-4 px-6 bg-base-200">
          <Suspense fallback={<SuspenseContent />}>
            <Outlet />
          </Suspense>
        </main> */}
      </div>
      <LeftSidebar />
    </div>
  );
};

export default Layout;
